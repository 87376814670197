/**
 * Searches for string via Regular Expression.
 * @param value The expression to be matched against.
 * @param str The string against which to match the regular expression. Can be
 *   an array of strings, then it returns true if the value is found in at
 *   least one string.
 * @param flag Regular Expression flag. Defaults to 'i' (ignoreCase)
 * @returns `true` if value found in string(s). `false` if not.
 */
export function includesString(value: string, str: string | string[], flag = 'i'): boolean {
  // eslint-disable-next-line no-useless-escape
  const pattern = value.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&');
  const regExp = new RegExp(pattern, flag);
  if (Array.isArray(str)) {
    return str.some(s => regExp.test(s));
  }
  return regExp.test(str);
}
