import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { DocumentDetailsComponent } from '@exb/document-details';
import { DocumentSetManagementService, DocumentViewContextMenuComponent } from '@exb/document-management';
import { DocumentViewModule, DocumentViewStateService } from '@exb/document-view';
import { SplitterModule } from 'primeng/splitter';
import { DocumentViewLayoutComponent } from './document-view-layout.component';

@NgModule({
  declarations: [DocumentViewLayoutComponent],
  imports: [
    CommonModule,
    DocumentDetailsComponent,
    DocumentViewContextMenuComponent,
    DocumentViewModule,
    RouterModule,
    SplitterModule,
  ],
  providers: [DocumentViewStateService, DocumentSetManagementService],
})
export class DocumentViewLayoutModule {}
