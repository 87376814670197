import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { combineLatest, map } from 'rxjs';

import { AuthenticationService } from '@exb/auth';
import { Permission, PermissionsService } from '@exb/permissions';
import { extractContextFromRoute } from '@exb/router-utils';

export function createPermissionGuard(permission: Permission): CanActivateFn {
  return route => {
    const router = inject(Router);
    const authenticationService = inject(AuthenticationService);
    const permissionsService = inject(PermissionsService);

    const context = extractContextFromRoute(route);
    const hasPermission$ = permissionsService.hasPermission(permission, context);
    const userInfo$ = authenticationService.userInfo$;

    return combineLatest([hasPermission$, userInfo$]).pipe(
      map(([hasPermission, userInfo]) => {
        if (hasPermission) {
          return true;
        }
        if (userInfo && !userInfo.isStaff) {
          const { resource, action } = permission;
          if ((resource === 'customer' || resource === 'solution') && action === 'read') {
            const { customerId, solutionId } = context;
            const params = { customerId, solutionId };
            const queryParams = Object.keys(params)
              .filter(key => !!params[key])
              .sort()
              .map(key => `${key}=${params[key]}`)
              .join('&');
            return router.parseUrl(`/main/support-request?${queryParams}`);
          }
        }
        return router.parseUrl('/no-permission');
      }),
    );
  };
}
