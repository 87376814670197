import { AbstractControl, ValidationErrors } from '@angular/forms';

export function strongPasswordValidatorFn({ value }: AbstractControl): ValidationErrors | null {
  if (value) {
    const minimumPasswordLength = 8;

    const isTooShort = value.length < minimumPasswordLength;
    const isEntirelyNumeric = /^\d+$/.test(value);

    if (isTooShort && isEntirelyNumeric) {
      return { requirementUnmet: true };
    }
    if (isTooShort) {
      return { tooShort: true };
    }
    if (isEntirelyNumeric) {
      return { entirelyNumeric: true };
    }
  }
  return null;
}
