<ng-container *transloco="let t">
  <p-splitter [panelSizes]="[45, 55]" [style]="{ height: '100%' }">
    <ng-template pTemplate>
      <div class="flex-grow-1 min-w-0 flex flex-column">
        <exb-document-view
          *ngIf="currentDocument$ | async as currentDocument; else noCurrentDocument"
          class="h-full"
          [document]="currentDocument || undefined"
          [documentSetName]="(currentDocumentSet$ | async)?.name"
          [index]="(currentDocumentIndex$ | async) || undefined"
          [availableCount]="(availableDocumentsCount$ | async) || undefined"
          [isDocSwitcherAvailable]="((availableDocumentsCount$ | async) || 0) > 0"
          [hasBackButton]="true"
          (backPress)="goBack()"
          (previousPress)="goToPreviousDocument()"
          (nextPress)="goToNextDocument()">
        </exb-document-view>
        <ng-template #noCurrentDocument>
          <ng-container *ngIf="solutionState.solution.documentCount; else noDocumentsInSolution">
            <div class="mini-header flex flex-row">
              <div class="mr-2">
                <button
                  pButton
                  type="button"
                  icon="material-icons-round mi-arrow-back"
                  class="p-button-text"
                  (click)="goBack()"
                  data-testid="back-button"></button>
              </div>
              <h5 class="heading-05 m-1">{{ t('documentSetSelector.title') }}</h5>
            </div>
            <exb-document-set-selector
              class="flex-1"
              (documentSetSelected)="onDocumentSetSelected($event)"
              data-testid="document-set-selector">
              <ng-container slot="info-text">
                <div class="mx-auto text-center info-text-container">
                  <span class="body-01 text-moderate" data-testid="dem-doc-select-counter">{{
                    t('documentSetSelector.infoText')
                  }}</span>
                </div>
              </ng-container>
            </exb-document-set-selector>
          </ng-container>
          <ng-template #noDocumentsInSolution>
            <exb-empty-status
              class="flex-grow-1 min-w-0 flex align-items-center"
              [status]="emptyStatus"
              data-testid="no-documents-info">
              <button
                pButton
                label="{{ t('dem.noDocuments.buttonLabel') }}"
                [exbEnableByPermission]="{ resource: 'document', action: 'read' }"
                data-testid="manage-documents-btn"
                routerLink="../document-management"></button>
            </exb-empty-status>
          </ng-template>
        </ng-template>
      </div>
    </ng-template>
    <ng-template pTemplate>
      <div class="flex-grow-1 min-w-0">
        <exb-dem-editor
          [disabled]="!(permissionsService.hasPermission({ resource: 'dem', action: 'update' }) | async)"
          data-testid="dem-editor"></exb-dem-editor>
      </div>
    </ng-template>
  </p-splitter>
</ng-container>
