import { CommonModule } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { EndTruncateComponent } from '@exb/components';
import { Solution } from '@exb/solution';
import { SolutionContextMenuComponent } from '@exb/solution-context-menu';
import { TranslocoModule } from '@jsverse/transloco';
import { TagModule } from 'primeng/tag';

@Component({
  selector: 'exb-solution-card-header',
  standalone: true,
  imports: [CommonModule, SolutionContextMenuComponent, TagModule, TranslocoModule, EndTruncateComponent],
  templateUrl: './solution-card-header.component.html',
  styleUrls: ['./solution-card-header.component.scss'],
})
export class SolutionCardHeaderComponent {
  @Input()
  solution!: Solution;

  @Output()
  readonly isMultiline = new EventEmitter<boolean>();

  private readonly headerDefaultHeight = 88;
  private readonly multilineObserver = new ResizeObserver(entries => {
    for (const entry of entries) {
      this.isMultiline.emit((entry.target as HTMLElement).offsetHeight > this.headerDefaultHeight);
    }
  });

  constructor(private el: ElementRef) {
    this.multilineObserver.observe(this.el.nativeElement);
  }
}
