import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AvatarComponentModule, FeatureAllowedModule, SideBarMenuComponent } from '@exb/components';
import { TranslocoModule } from '@jsverse/transloco';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';

import { MainLayoutComponent } from './main-layout.component';

@NgModule({
  declarations: [MainLayoutComponent],
  imports: [
    AvatarComponentModule,
    CommonModule,
    BreadcrumbModule,
    RouterModule,
    SideBarMenuComponent,
    TranslocoModule,
    FeatureAllowedModule,
    ButtonModule,
  ],
})
export class MainLayoutModule {}
