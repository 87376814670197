import { Component, DestroyRef, HostListener, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslocoService } from '@jsverse/transloco';
import { marker } from '@jsverse/transloco-keys-manager/marker';
import { DialogService } from 'primeng/dynamicdialog';
import { BehaviorSubject, combineLatest, firstValueFrom, of } from 'rxjs';
import { debounceTime, filter, map, startWith, switchMap } from 'rxjs/operators';

import { EmptyStateMessage, LocalStorageService, TranslationUtilsService, createTrackByKeyFn } from '@exb/components';
import { Customer } from '@exb/customer';
import { Permission, PermissionsService } from '@exb/permissions';
import { Solution, SolutionService } from '@exb/solution';
import { SolutionDetailsModalComponent } from '@exb/solution-context-menu';

import { includesString } from '../../utils';

type SortOption = 'name' | 'customer' | 'lastCreated' | 'lastUpdated';

type SolutionSortFn = (a: Solution, b: Solution) => number;

@Component({
  selector: 'exb-solution-browser',
  templateUrl: './solution-browser.component.html',
  styleUrls: ['./solution-browser.component.scss'],
})
export class SolutionBrowserComponent implements OnInit {
  readonly form = new FormGroup({
    // #219 initial sorting by creation date
    sort: new FormControl<SortOption>('lastCreated'),
    search: new FormControl<string>(''),
  });

  get searchInput() {
    return this.form.get('search') as FormControl;
  }

  solutionList: Solution[] = [];

  private readonly _emptyStateMessage$ = new BehaviorSubject<EmptyStateMessage | undefined>(undefined);

  readonly emptyStateMessage$ = this._emptyStateMessage$.pipe(
    switchMap(emptyStateMessage =>
      emptyStateMessage
        ? this.translationUtils.translateFields(emptyStateMessage, 'header', 'description')
        : of(undefined),
    ),
  );

  sortOptions = [
    { name: marker('solutions.sortBy.name'), code: 'name' },
    { name: marker('solutions.sortBy.lastCreated'), code: 'lastCreated' },
    { name: marker('solutions.sortBy.lastUpdated'), code: 'lastUpdated' },
  ];

  options$ = this.translationUtils.translateFieldInList(this.sortOptions, 'name');

  isOnScrollMode = false;

  /**
   * detecting scroll, so we can activate the app header border bottom
   */
  @HostListener('scroll', ['$event'])
  onScroll(event: any) {
    this.isOnScrollMode = true;
    // when reaching the top, the header bottom border should be hidden
    if (event.target.scrollTop === 0) {
      this.isOnScrollMode = false;
    }
  }

  private readonly nameSortFn: SolutionSortFn = (a, b) => a.name.localeCompare(b.name);

  private readonly customerSortFn: SolutionSortFn = (a, b) =>
    a.customer && b.customer ? a.customer.name.localeCompare(b.customer.name) : 0;

  private readonly lastCreatedSortFn: SolutionSortFn = (a, b) => b.createdAt.getTime() - a.createdAt.getTime();

  private readonly lastUpdatedSortFn: SolutionSortFn = (a, b) => b.updatedAt.getTime() - a.updatedAt.getTime();

  protected readonly customer: Customer;

  protected readonly canChangeCustomer$ = this.permissionsService.hasAccessToMultipleCustomers();

  protected readonly solutionCreatePermission: Permission = { resource: 'solution', action: 'create' };

  readonly canCreateSolution$ = this.permissionsService.hasPermission(this.solutionCreatePermission);

  readonly trackById = createTrackByKeyFn<Solution>('id');

  private readonly destroyRef = inject(DestroyRef);

  constructor(
    private readonly solutionService: SolutionService,
    private readonly translationUtils: TranslationUtilsService,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly localStorageService: LocalStorageService,
    private readonly dialogService: DialogService,
    private readonly translocoService: TranslocoService,
    private readonly permissionsService: PermissionsService,
  ) {
    this.customer = this.activatedRoute.snapshot.data['customer'] as Customer;
  }

  ngOnInit(): void {
    const searchText$ = this.form.controls.search.valueChanges.pipe(
      startWith(this.form.controls.search.defaultValue),
      debounceTime(500),
      map(value => value || undefined),
    );

    const sortBy$ = this.form.controls.sort.valueChanges.pipe(
      startWith(this.form.controls.sort.defaultValue),
      map(sortBy => sortBy || 'lastCreated'),
    );

    const allSolutions$ = this.solutionService.changedSolutions();

    combineLatest([allSolutions$, searchText$, sortBy$, this.canCreateSolution$])
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(([solutions, searchText, sortBy, canCreateSolution]) => {
        const totalCount = solutions.length;
        if (searchText) {
          solutions = solutions.filter(solution =>
            includesString(searchText, [solution.name, solution.customer?.name]),
          );
        }
        const sortFns: Record<SortOption, SolutionSortFn> = {
          name: this.nameSortFn,
          customer: this.customerSortFn,
          lastCreated: this.lastCreatedSortFn,
          lastUpdated: this.lastUpdatedSortFn,
        };
        this.solutionList = solutions.sort(sortFns[sortBy]);

        if (totalCount === 0) {
          let emptyMessage: EmptyStateMessage;
          if (canCreateSolution) {
            emptyMessage = {
              header: marker('emptyStateMessage.solutions.uncreated.header'),
              description: marker('emptyStateMessage.solutions.uncreated.descriptionWithCreateHint'),
              action: {
                label: this.translocoService.translate('solutionBrowser.createSolution.buttonLabel'),
                icon: 'material-icons-round mi-add',
                disabled: false,
              },
            };
          } else {
            emptyMessage = {
              header: marker('emptyStateMessage.solutions.uncreated.header'),
              description: marker('emptyStateMessage.solutions.uncreated.description'),
            };
          }
          this._emptyStateMessage$.next(emptyMessage);
        } else if (solutions.length === 0) {
          this._emptyStateMessage$.next({
            header: marker('emptyStateMessage.solutions.unfound.header'),
            description: marker('emptyStateMessage.solutions.unfound.description'),
            metadata: searchText,
          });
        } else {
          this._emptyStateMessage$.next(undefined);
        }

        if (totalCount === 0) {
          this.form.disable({ emitEvent: false });
        } else {
          this.form.enable({ emitEvent: false });
        }
      });
  }

  selectDifferentCustomer() {
    this.localStorageService.removeItem('activeCustomer');
    this.router.navigate(['/main/customers']);
  }

  onCreateSolution() {
    this.dialogService.open(SolutionDetailsModalComponent, {
      header: this.translocoService.translate('solution.menu.create.dialog.header'),
      draggable: false,
      data: {
        actionLabel: this.translocoService.translate('solution.menu.create.dialog.save'),
        inputLabel: this.translocoService.translate('solution.menu.create.dialog.name'),
        textareaInputLabel: this.translocoService.translate('solution.menu.create.dialog.description'),
        errorMessages: {
          generic: this.translocoService.translate('solution.menu.create.dialog.toast.error'),
          required: this.translocoService.translate('solution.menu.create.dialog.error.required'),
        },
        successMessage: this.translocoService.translate('solution.menu.create.dialog.toast.success'),
        submit: async (name: string, description: string | undefined) =>
          await firstValueFrom(this.onSubmitCreateSolution(name, description)),
      },
      styleClass: 'dialog-content-overflow-y-visible',
    });
  }

  onSubmitCreateSolution(name: string, description: string | undefined) {
    return this.solutionService.createSolution(name, description).pipe(
      takeUntilDestroyed(this.destroyRef),
      filter(id => !!id),
      switchMap(id => this.router.navigate([id], { relativeTo: this.activatedRoute })),
    );
  }

  focusFirstSolution() {
    const firstOpenSolutionButton = document.getElementsByClassName('open-solution')[0] as HTMLElement | undefined;
    (firstOpenSolutionButton?.firstChild as HTMLButtonElement)?.focus();
  }
}
