<section class="p-3" *transloco="let t">
  <div class="flex align-items-top top-row">
    <h3 class="heading-03" exbEndTruncate [linesClamped]="2" tooltipPosition="top" data-testid="solution-name">
      {{ solution.name }}
    </h3>
    <exb-solution-context-menu [solution]="solution"></exb-solution-context-menu>
  </div>
  <div class="flex align-items-center justify-content-between mt-2 second-row">
    <h3 class="body-03 m-0" exbEndTruncate data-testid="customer-name">{{ solution.customer.name }}</h3>
    <span class="flex justify-content-center">
      <p-tag
        class="ml-1"
        *ngIf="solution.deploymentId"
        icon="material-icons-round mi-rocket-launch"
        value="{{ t('solutionBrowser.solutionCard.tags.deployed') }}"
        styleClass="tag-aqua"
        [rounded]="true"
        data-testid="deployed-tag"></p-tag>
    </span>
  </div>
</section>
