<div class="flex flex-column h-full" *transloco="let t">
  <exb-app-header [hasBottomBorder]="false">
    <div class="flex flex-row justify-content-between">
      <h1 class="heading-01 m-0">{{ t('solutions.title') }}</h1>
    </div>
  </exb-app-header>
  <div class="flex-grow-1 min-w-0 flex align-items-center justify-content-center">
    <div class="max-w-30rem m-auto text-center">
      <h4 class="heading-04 text-moderate m-0 mb-2">{{ t('supportRequest.header') }}</h4>
      <div class="body-01 mb-3 text-moderate" aria-description="request support">
        {{ t('supportRequest.description') }}
      </div>
      <button
        pButton
        pRipple
        type="button"
        [label]="t('supportRequest.actions.requestSupport')"
        data-testid="request-support-button"
        (click)="requestSupport()"></button>
    </div>
  </div>
</div>
