<!-- TODO: Investigate on transloco-keys-manager not finding some translation keys in template files -->
<!--
  t(
    profile.actions.logout,
    profile.language,
    profile.legalDocuments,
    profile.legalContent,
    profile.requestNda
  )
-->
<!--
  t(
    legalDocuments.urls.termsAndConditions,
    legalDocuments.urls.privacyPolicy
  )
-->
<div class="px-4 pt-4" *transloco="let t">
  <div class="flex h-6rem align-items-start">
    <h1 class="heading-01 m-0 flex-grow-1">{{ t('profile.title') }}</h1>
    <button pButton (click)="logout()" data-testid="logout-button">
      <i class="material-icons-round mi-logout mr-2"></i>
      {{ t('profile.actions.logout') }}
    </button>
  </div>
  <div>
    <h2 class="heading-05 mb-2 mt-0">{{ t('profile.language') }}</h2>
    <form [formGroup]="form">
      <div class="flex">
        <div class="mr-3 flex align-items-center body-01" *ngFor="let language of languages">
          <p-radioButton [inputId]="language.label" [value]="language.value" formControlName="language" />
          <label [for]="language.label" class="ml-2">
            {{ language.label }}
          </label>
        </div>
      </div>
    </form>
  </div>
  <div class="mt-5 info-text-container">
    <h2 class="heading-05 mt-0 mb-2 mb-2">{{ t('profile.legalDocuments') }}</h2>
    <p
      class="white-space-break mt-0 mb-3 body-03 mt-0 line-height-4 text-moderate"
      [innerHTML]="t('profile.legalContent')"></p>
    <button
      pButton
      class="p-button-text mt-4 -ml-2 pointer-events-auto"
      [disabled]="!(hasPermission$ | async)"
      [pTooltip]="disabledTooltip"
      [tooltipStyleClass]="'text-center'"
      tooltipPosition="bottom"
      data-testid="request-nda"
      [label]="t('profile.requestNda')"
      (click)="sentNdaRequest()"
      severity="secondary"></button>
  </div>
</div>
