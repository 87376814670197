<p-splitter [style]="{ height: '100%' }" [panelSizes]="[67, 33]">
  <ng-template pTemplate>
    <div class="flex-grow-1 min-w-0">
      <exb-document-view
        class="h-full"
        [document]="(currentDocument$ | async) || undefined"
        [documentSetName]="(currentDocumentSet$ | async)?.name"
        [index]="(currentDocumentIndex$ | async) || undefined"
        [availableCount]="(availableDocumentsCount$ | async) || undefined"
        [hasBackButton]="true"
        (backPress)="goBack()"
        (previousPress)="goToPreviousDocument()"
        (nextPress)="goToNextDocument()"
        (pageLoaded)="setCurrentPage($event)">
      </exb-document-view>
    </div>
  </ng-template>
  <ng-template pTemplate>
    <div class="flex-grow-1 min-w-0">
      <exb-document-details
        [isInDocumentSetScope]="!!(currentDocumentSet$ | async)"
        [documentDetails]="currentDocument$ | async"
        [setsContainingDocument]="setsContainingDocument$ | async"
        [qualityMetrics]="qualityMetrics$ | async"
        [copies]="documentCopies$ | async">
        <exb-document-view-context-menu [document]="currentDocument$ | async"></exb-document-view-context-menu>
      </exb-document-details>
    </div>
  </ng-template>
</p-splitter>
