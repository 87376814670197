<p-card *transloco="let t" styleClass="solution-card flex flex-column">
  <ng-template pTemplate="header">
    <exb-solution-card-header
      [solution]="solution"
      (isMultiline)="$isHeaderMultiline.next($event)"></exb-solution-card-header>
  </ng-template>

  <exb-solution-card-body
    [solutionStatus]="(solutionStatus$ | async)!"
    [solutionDescription]="solution.description"
    [descriptionClampedLines]="(descriptionClampedLines$ | async)!"></exb-solution-card-body>

  <ng-template pTemplate="footer">
    <exb-solution-card-footer
      class="transition-hidden"
      [resultsButtonEnabled]="!!(canViewResults$ | async)"
      (openSolution)="navigateToSolution()"
      (showResults)="navigateToResults()"></exb-solution-card-footer>
  </ng-template>
</p-card>
