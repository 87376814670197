import { Component } from '@angular/core';

@Component({
  selector: 'exb-not-found',
  template: `
    <div class="p-3">
      <img src="https://img.freepik.com/free-vector/glitch-error-404-page_23-2148075111.jpg?w=2000" alt="not-found" />
      <button pButton pRipple type="button" label="go to homepage" routerLink="/" class="go-to-home"></button>
    </div>
  `,
  styleUrls: ['./not-found.component.scss'],
})
export class NotFoundComponent {}
