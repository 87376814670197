import { gql } from 'apollo-angular';

export type RequestNdaSuccess = { __typename: 'RequestNDA'; accepted: boolean };
export type RequestNdaError = { __typename: 'RequestNDAError'; reason: string };
export type RequestNdaResponse = RequestNdaSuccess | RequestNdaError;

export const requestNdaMutation = gql`
  mutation requestNdaMutation {
    requestNda {
      ... on RequestNDA {
        __typename
        accepted
      }
      ... on RequestNDAError {
        __typename
        reason
      }
    }
  }
`;

export type RequestSupportMutationResponse =
  | { __typename: 'RequestSupport'; accepted: boolean }
  | { __typename: 'RequestSupportError'; reason: string };

export const requestSupportMutation = gql`
  mutation requestSupportMutation($customerId: UUID, $solutionId: UUID) {
    requestSupport(input: { customerId: $customerId, solutionId: $solutionId }) {
      ... on RequestSupport {
        __typename
        accepted
      }
      ... on RequestSupportError {
        __typename
        reason
      }
    }
  }
`;
