import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService, RegistrationData } from '@exb/auth';
import { ToastsMessageService } from '@exb/components';
import { LoggingService } from '@exb/logging';
import { TranslocoService } from '@jsverse/transloco';
import { Subscription, catchError, switchMap, throwError } from 'rxjs';

import { strongPasswordValidatorFn } from '../../../services';

type BackendValidationErrors = Record<string, string>;

@Component({
  selector: 'exb-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss'],
})
export class SignUpComponent implements OnInit, OnDestroy {
  readonly credentialForm = new FormGroup({
    email: new FormControl(''),
    password: new FormControl(''),
  });

  readonly userInfoForm = new FormGroup({
    firstName: new FormControl(''),
    lastName: new FormControl(''),
  });

  showPassword = false;

  currentPage = 1;

  loading = false;

  language = 'en';

  private readonly subscriptions = new Subscription();

  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly toast: ToastsMessageService,
    private readonly transloco: TranslocoService,
    private readonly loggingService: LoggingService,
  ) {}

  ngOnInit(): void {
    this.language = this.transloco.getActiveLang();

    this.currentPage = this.route.snapshot.queryParams['page'] === '2' ? 2 : 1;
    if (this.currentPage !== 1 && this.credentialForm.pristine) {
      this.goToPage(1);
    }

    this.subscriptions.add(
      this.route.queryParams.subscribe(params => {
        this.currentPage = params['page'] === '2' ? 2 : 1;
      }),
    );

    this.subscriptions.add(
      this.authenticationService.loginEvent$.subscribe(event => {
        this.loading = event.type === 'login-requested';
        if (event.type === 'login-successful') {
          const returnUrl = this.route.snapshot.queryParamMap.get('returnUrl') || '/';
          this.router.navigateByUrl(returnUrl, { replaceUrl: true });
        } else if (event.type === 'login-failed') {
          const error = event.error;
          if (error instanceof HttpErrorResponse && error.status <= 0) {
            this.toast.showError(this.transloco.translate('genericErrors.clientOffline'));
          } else {
            this.toast.showError(this.transloco.translate('genericErrors.serverError'));
          }
        }
      }),
    );
  }

  onCredentialSubmit() {
    if (!this.validateCredentialForm()) {
      return;
    }

    this.goToPage(2);
  }

  onUserInfoSubmit() {
    if (!this.validateUserInfoForm()) {
      return;
    }

    const registrationData = { ...this.credentialForm.value, ...this.userInfoForm.value } as RegistrationData;
    const { email, password } = registrationData;

    this.loading = true;
    this.subscriptions.add(
      this.authenticationService
        .register(registrationData)
        .pipe(
          catchError(error => {
            if (error instanceof HttpErrorResponse && error.status === HttpStatusCode.BadRequest) {
              this.showBackendValidationErrors(error.error as BackendValidationErrors);
              if (!this.credentialForm.valid) {
                this.goToPage(1);
              }
            }
            if (this.credentialForm.valid && this.userInfoForm.valid) {
              if (error instanceof HttpErrorResponse && error.status <= 0) {
                this.toast.showError(this.transloco.translate('genericErrors.clientOffline'));
              } else {
                this.toast.showError(this.transloco.translate('genericErrors.serverError'));
              }
            }
            return throwError(() => error);
          }),
          switchMap(() => this.authenticationService.login({ email, password })),
        )
        .subscribe({
          error: () => {
            this.loading = false;
          },
        }),
    );
  }

  private goToPage(page: number) {
    this.router.navigate(['.'], {
      queryParams: { page: page === 2 ? 2 : 1 },
      relativeTo: this.route,
      queryParamsHandling: 'merge',
    });
  }

  private validateCredentialForm(): boolean {
    const { email, password } = this.credentialForm.controls;
    email.setValidators([Validators.required, Validators.email]);
    email.updateValueAndValidity();
    password.setValidators([Validators.required, strongPasswordValidatorFn]);
    password.updateValueAndValidity();
    if (!this.credentialForm.valid) {
      email.markAsDirty();
      password.markAsDirty();
      return false;
    }
    return true;
  }

  private validateUserInfoForm(): boolean {
    const { firstName, lastName } = this.userInfoForm.controls;
    firstName.setValidators(Validators.required);
    firstName.updateValueAndValidity();
    lastName.setValidators(Validators.required);
    lastName.updateValueAndValidity();
    if (!this.userInfoForm.valid) {
      this.userInfoForm.controls.firstName.markAsDirty();
      this.userInfoForm.controls.lastName.markAsDirty();
      return false;
    }
    return true;
  }

  private showBackendValidationErrors(errors: BackendValidationErrors) {
    const { email, password } = this.credentialForm.controls;
    const { firstName, lastName } = this.userInfoForm.controls;
    for (const key in errors) {
      const values = errors[key];
      switch (key) {
        case 'email':
          email.setErrors({ backend: values });
          break;
        case 'password':
          password.setErrors({ backend: values });
          break;
        case 'first_name':
          firstName.setErrors({ backend: values });
          break;
        case 'last_name':
          lastName.setErrors({ backend: values });
          break;
        default:
          this.loggingService.error('Sign in validation errors', values);
      }
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
