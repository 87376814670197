import { Component } from '@angular/core';
import { PermissionTranslationService } from '@exb/permissions';
import { marker } from '@jsverse/transloco-keys-manager/marker';
import { MenuItem, TooltipOptions } from 'primeng/api';

const initialTooltipOptions: Readonly<TooltipOptions> = {
  tooltipPosition: 'bottom',
  tooltipLabel: '',
  tooltipEvent: 'hover',
};

@Component({
  selector: 'exb-dm-doc-management',
  templateUrl: './doc-management.component.html',
  styleUrls: ['./doc-management.component.scss'],
})
export class DocManagementComponent {
  readonly tabItems: MenuItem[] = [
    {
      id: 'document-list-tab',
      label: marker('documentManagement.docTab'),
      routerLink: 'all',
      permission: { resource: 'document', action: 'read' },
    },
    {
      id: 'document-set-list-tab',
      label: marker('documentManagement.setsTab'),
      routerLink: 'sets',
      permission: { resource: 'document_set', action: 'read' },
    },
  ];

  constructor(private readonly permissionTranslationService: PermissionTranslationService) {
    for (const tabItem of this.tabItems) {
      this.permissionTranslationService
        .getPermissionTooltipDataOnce(tabItem['permission'])
        .subscribe(({ tooltipText, hasPermission }) => {
          Object.assign(
            tabItem,
            this.permissionTranslationService.getAdditionalMenuItemOptionsForTooltipData(
              { tooltipText, hasPermission },
              initialTooltipOptions,
            ),
          );
          tabItem['hasPermission'] = hasPermission;
        });
    }
  }
}
