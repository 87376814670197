import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

import { DEMComponent } from '../pages/dem';

function removeUrlParams(url: string) {
  return url.replace(/(\?.*$)/, '');
}

@Injectable()
export class DEMGuardService implements CanDeactivate<DEMComponent> {
  constructor(private readonly router: Router) {}

  canDeactivate(
    component: DEMComponent,
    _currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot,
  ): boolean | Observable<boolean> {
    if (!nextState || removeUrlParams(nextState.url) !== removeUrlParams(currentState.url)) {
      return component.canLeavePage();
    }
    return true;
  }
}
