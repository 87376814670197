import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router } from '@angular/router';
import { catchError, map, of } from 'rxjs';

import { extractContextFromRoute } from '@exb/router-utils';
import { SolutionService } from '@exb/solution';

export const solutionExistsGuard: CanActivateFn = (route: ActivatedRouteSnapshot) => {
  const solutionService = inject(SolutionService);
  const router: Router = inject(Router);
  const context = extractContextFromRoute(route);
  if (!context?.solutionId) {
    throw new Error('Wrong context value!');
  }

  return solutionService.getSolution(context.solutionId).pipe(
    map(() => true),
    catchError(() => of(router.parseUrl('something-went-wrong'))),
  );
};
