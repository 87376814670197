import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslocoModule } from '@jsverse/transloco';
import { CardModule } from 'primeng/card';
import { Observable, Subject, combineLatest, distinctUntilChanged, map, of, startWith, switchMap } from 'rxjs';

import { Permission, PermissionsService, Resource } from '@exb/permissions';
import { Solution } from '@exb/solution';
import { ExtractionResultService } from '@exb/workflow';

import { SolutionCardBodyComponent } from './solution-card-body/solution-card-body.component';
import { SolutionStatus } from './solution-card-body/solution-status.model';
import { SolutionCardFooterComponent } from './solution-card-footer/solution-card-footer.component';
import { SolutionCardHeaderComponent } from './solution-card-header/solution-card-header.component';

@Component({
  selector: 'exb-solution-card',
  standalone: true,
  imports: [
    CommonModule,
    CardModule,
    TranslocoModule,
    SolutionCardHeaderComponent,
    SolutionCardBodyComponent,
    SolutionCardFooterComponent,
  ],
  templateUrl: './solution-card.component.html',
  styleUrls: ['./solution-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SolutionCardComponent implements OnInit {
  @Input()
  solution!: Solution;

  readonly descriptionClampedLines$: Observable<number>;
  readonly $isHeaderMultiline = new Subject<boolean>();

  canViewResults$!: Observable<boolean>;
  solutionStatus$!: Observable<SolutionStatus>;

  constructor(
    private readonly extractionResultService: ExtractionResultService,
    private readonly permissionsService: PermissionsService,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
  ) {
    this.descriptionClampedLines$ = this.$isHeaderMultiline.pipe(
      distinctUntilChanged(),
      map(isHeaderMultiline => (isHeaderMultiline ? 2 : 3)),
    );
  }

  ngOnInit() {
    const areResultsReady$ = of(this.solution).pipe(
      switchMap(solution => {
        if (solution.workflowId && solution.workflowVersion !== undefined) {
          return this.extractionResultService
            .watchOverallExtractionStatus(solution.workflowId, solution.workflowVersion, { solutionId: solution.id })
            .pipe(map(status => status === 'available'));
        }
        return of(false);
      }),
      startWith(false),
      distinctUntilChanged(),
    );

    const availableResources$: Observable<Resource[]> = of(this.solution).pipe(
      switchMap(solution => {
        return combineLatest([
          of(solution.documentCount ? 'document' : undefined) as Observable<Resource | undefined>,
          of(solution.demId ? 'dem' : undefined) as Observable<Resource | undefined>,
          of(solution.workflowId ? 'workflow' : undefined) as Observable<Resource | undefined>,
          of(solution.goldData?.solutionId ? 'gold_data' : undefined) as Observable<Resource | undefined>,
          areResultsReady$.pipe(map(ready => (ready ? 'results' : undefined))) as Observable<Resource | undefined>,
          of(solution.e2eMeasurements ? 'e2e_measurement' : undefined) as Observable<Resource | undefined>,
        ]);
      }),
      map(resources => resources.filter(resource => !!resource) as Resource[]),
    );

    const readPermissions: Permission[] = [
      { resource: 'document', action: 'read' },
      { resource: 'dem', action: 'read' },
      { resource: 'workflow', action: 'read' },
      { resource: 'gold_data', action: 'read' },
      { resource: 'results', action: 'read' },
      { resource: 'e2e_measurement', action: 'read' },
    ];
    const accessibleResources$ = combineLatest(
      readPermissions.map(permission =>
        this.permissionsService
          .hasPermission(permission, { solutionId: this.solution.id })
          .pipe(map(hasPermission => (hasPermission ? permission.resource : undefined))),
      ),
    ).pipe(
      map(resourcesWithReadPermission => resourcesWithReadPermission.filter(resource => !!resource) as Resource[]),
    );

    this.solutionStatus$ = combineLatest([accessibleResources$, availableResources$]).pipe(
      map(([accessibleResources, availableResources]) => {
        const resourceMapping: Record<keyof SolutionStatus, Resource> = {
          documents: 'document',
          dem: 'dem',
          workflow: 'workflow',
          goldData: 'gold_data',
          results: 'results',
          e2eMeasurement: 'e2e_measurement',
        };
        return Object.keys(resourceMapping).reduce((s, key) => {
          const resource = resourceMapping[key];
          const isAccessible = accessibleResources.includes(resource);
          const isAvailable = isAccessible && availableResources.includes(resource);
          return Object.assign({}, s, { [key]: { isAccessible, isAvailable } });
        }, {} as SolutionStatus);
      }),
    );

    this.canViewResults$ = this.solutionStatus$.pipe(map(({ results }) => results.isAccessible && results.isAvailable));
  }

  navigateToSolution() {
    this.router.navigate([this.solution.id], { relativeTo: this.activatedRoute });
  }

  navigateToResults() {
    this.router.navigate([this.solution.id, 'result-extraction'], {
      relativeTo: this.activatedRoute,
    });
  }
}
