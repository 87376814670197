import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { map } from 'rxjs';

import { AuthenticationService } from '@exb/auth';

export const canActivateProtectedPage: CanActivateFn = (_route, state) => {
  const router = inject(Router);
  const authenticationService = inject(AuthenticationService);
  return authenticationService.isLoggedIn$.pipe(
    map(canActivate => {
      if (canActivate === false) {
        let url = '/signin';
        if (state.url !== '/main/customers') {
          const params = new URLSearchParams();
          params.set('returnUrl', state.url);
          url += `?${params.toString()}`;
        }
        return router.parseUrl(url);
      }
      return canActivate;
    }),
  );
};
