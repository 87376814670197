<div *transloco="let t">
  <button
    (click)="showResults.emit()"
    [label]="t('solutionBrowser.solutionCard.buttons.showResults')"
    [disabled]="!resultsButtonEnabled"
    pButton
    data-testid="show-results-button"
    class="p-button-text show-results"></button>

  <p-button
    class="ml-2 open-solution"
    (click)="openSolution.emit()"
    [label]="t('solutionBrowser.solutionCard.buttons.openSolution')"
    data-testid="goto-solution-button"></p-button>
</div>
