import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { Observable, map } from 'rxjs';
import { UserNames } from '../models';

const USER_AVATAR_INFO_QUERY = gql`
  query ($userId: UUID!) {
    user(id: $userId) {
      firstName
      lastName
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private readonly apollo: Apollo) {}

  getUserNames(userId: string): Observable<UserNames> {
    return this.apollo
      .query<{ user: UserNames | null }>({ query: USER_AVATAR_INFO_QUERY, variables: { userId } })
      .pipe(map(({ data: { user } }) => ({ firstName: user?.firstName ?? '', lastName: user?.lastName ?? '' })));
  }
}
