import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { TooltipModule } from 'primeng/tooltip';

import { EndTruncateComponent } from '@exb/components';

import { SolutionStatus } from './solution-status.model';

@Component({
  selector: 'exb-solution-card-body',
  standalone: true,
  imports: [CommonModule, TranslocoModule, TooltipModule, EndTruncateComponent],
  templateUrl: './solution-card-body.component.html',
  styleUrls: ['./solution-card-body.component.scss'],
})
export class SolutionCardBodyComponent {
  @Input()
  solutionStatus!: SolutionStatus;

  @Input()
  solutionDescription = '';

  @Input()
  descriptionClampedLines = 3;
}
