import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { ButtonModule } from 'primeng/button';

@Component({
  selector: 'exb-solution-card-footer',
  standalone: true,
  imports: [CommonModule, ButtonModule, TranslocoModule],
  templateUrl: './solution-card-footer.component.html',
  styleUrls: ['./solution-card-footer.component.scss'],
})
export class SolutionCardFooterComponent {
  @Input()
  resultsButtonEnabled = false;

  @Output()
  showResults = new EventEmitter<void>();

  @Output()
  openSolution = new EventEmitter<void>();
}
