import { GoogleSigninButtonModule, SocialLoginModule } from '@abacritt/angularx-social-login';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslocoModule } from '@jsverse/transloco';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { PasswordModule } from 'primeng/password';
import { TooltipModule } from 'primeng/tooltip';

import { AuthenticationLayoutComponent } from './authentication-layout';
import { SignInComponent } from './sign-in';
import { SignUpComponent } from './sign-up';

@NgModule({
  declarations: [SignInComponent, SignUpComponent, AuthenticationLayoutComponent],
  imports: [
    ButtonModule,
    CommonModule,
    FormsModule,
    GoogleSigninButtonModule,
    InputTextModule,
    PasswordModule,
    ReactiveFormsModule,
    RouterModule,
    SocialLoginModule,
    TooltipModule,
    TranslocoModule,
  ],
})
export class AuthenticateModule {}
