import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthenticationService } from '@exb/auth';
import { ToastsMessageService } from '@exb/components';
import { LoggingService } from '@exb/logging';
import { PermissionsService } from '@exb/permissions';
import { TranslocoService } from '@jsverse/transloco';

import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { EMPTY, Observable, catchError, combineLatest, map, shareReplay, tap } from 'rxjs';

import { SortingFilterStoreService } from '@exb/document-management';
import { SupportService } from '../../services/support.service';

@Component({
  selector: 'exb-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  readonly languages = [
    { label: 'English', value: 'en' },
    { label: 'Deutsch', value: 'de' },
  ];

  form = new FormGroup({
    language: new FormControl(this.translocoService.getActiveLang()),
  });

  readonly hasPermission$: Observable<boolean>;
  disabledTooltip: string;

  private readonly destroyRef = inject(DestroyRef);

  constructor(
    private readonly router: Router,
    private readonly authenticationService: AuthenticationService,
    private readonly translocoService: TranslocoService,
    private readonly sortingFilterStoreService: SortingFilterStoreService,
    private readonly toastsMessageService: ToastsMessageService,
    private readonly supportService: SupportService,
    private readonly permissionsService: PermissionsService,
    private readonly loggingService: LoggingService,
  ) {
    this.hasPermission$ = combineLatest([
      this.authenticationService.userInfo$.pipe(
        map(userInfo => !!userInfo?.isStaff),
        shareReplay(),
      ),
      this.permissionsService
        .getCustomerIdsWithPermission({ resource: 'customer', action: 'read' })
        .pipe(map(customerIds => customerIds.length > 0)),
      this.translocoService.langChanges$,
    ]).pipe(
      tap(([isStaff, , language]) => {
        if (isStaff) {
          this.disabledTooltip = this.translocoService.translate('profile.disabledNdaTooltip', {}, language);
          return;
        }
        this.disabledTooltip = this.translocoService.translate('profile.disabledNdaTooltipNoPermission', {}, language);
      }),
      map(([isStaff, hasPermission]) => !isStaff && hasPermission),
    );
  }

  ngOnInit(): void {
    this.form.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(({ language }) => {
      this.translocoService.setActiveLang(language!);
      // TODO: Replace with a backend persistence
      localStorage.setItem('lang', language!);
    });
  }

  logout() {
    this.authenticationService
      .logout()
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        catchError(error => {
          this.loggingService.error('Error on logout', error);
          return EMPTY;
        }),
      )
      .subscribe({
        complete: () => {
          this.sortingFilterStoreService.clearSFData();
          this.router.navigateByUrl('/signin', { replaceUrl: true });
        },
      });
  }

  sentNdaRequest(): void {
    this.supportService
      .requestNda()
      .pipe(
        catchError(() => {
          this.toastsMessageService.showError(this.translocoService.translate('profile.requestNdaError'));
          return EMPTY;
        }),
      )
      .subscribe(() => {
        this.toastsMessageService.showSuccess(this.translocoService.translate('profile.requestNdaSuccess'));
      });
  }
}
