<exb-authentication-layout *transloco="let t">
  <h1 class="heading-01 text-primary text-center">{{ t('signin.title') }}</h1>

  <fieldset [disabled]="loading">
    <form [formGroup]="signinForm" (ngSubmit)="onSubmit()">
      <div class="flex flex-column gap-4" #formContainer>
        <!--
          The Google sign in button is rendered when formContainer has a non-null width because it blocks
          the rendering of the entire component when running Jest.
        -->
        <div class="google-sso-button-container">
          <asl-google-signin-button
            *ngIf="formContainer.offsetWidth"
            type="standard"
            logo_alignment="center"
            [locale]="language"
            [width]="240"
            size="large">
          </asl-google-signin-button>
        </div>

        <div class="form-separator">
          <hr />
          <span class="body-01">{{ t('signin.separator') }}</span>
          <hr />
        </div>

        <div class="flex flex-column">
          <div class="field field-lg">
            <input
              type="email"
              pInputText
              formControlName="email"
              [placeholder]="t('signin.fields.email')"
              autocomplete="email" />
            <ng-container *ngIf="signinForm.controls.email.dirty">
              <small class="p-error block" *ngFor="let error of signinForm.controls.email.errors | keyvalue">
                {{
                  error.key === 'required'
                    ? t('signin.errors.emptyEmail')
                    : error.key === 'email'
                      ? t('signin.errors.invalidEmail')
                      : error.value
                }}
              </small>
            </ng-container>
          </div>
          <div class="field field-lg">
            <input
              type="password"
              pInputText
              formControlName="password"
              [placeholder]="t('signin.fields.password')"
              autocomplete="current-password" />
            <ng-container *ngIf="signinForm.controls.password.dirty">
              <small class="p-error block" *ngFor="let error of signinForm.controls.password.errors | keyvalue">
                {{
                  error.key === 'required'
                    ? t('signin.errors.emptyPassword')
                    : error.key === 'invalidCredential'
                      ? t('signin.errors.invalidCredential')
                      : error.value
                }}
              </small>
            </ng-container>
          </div>
          <button pButton type="submit" class="p-button-block" [loading]="loading" data-testid="sign-in-button">
            {{ loading ? '' : t('signin.actions.signIn') }}
          </button>

          <div class="text-center mt-3">
            <span class="body-03 text-moderate">{{ t('signin.signUpMessage') }}</span>
            <button
              pButton
              type="button"
              routerLink="/signup"
              class="p-button-link px-2 py-0"
              queryParamsHandling="preserve"
              data-testid="sign-up-button">
              {{ t('signin.links.signUp') }}
            </button>
          </div>
        </div>
      </div>
    </form>
  </fieldset>
</exb-authentication-layout>
