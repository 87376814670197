import { GoogleLoginProvider, SocialAuthServiceConfig, SocialLoginModule } from '@abacritt/angularx-social-login';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { ConfirmationService } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ToastModule } from 'primeng/toast';

import { APP_CONTEXT_PROVIDER } from '@exb/app-context';
import { ApiCallInterceptor, AuthenticationService } from '@exb/auth';
import { ConfirmDialogComponent } from '@exb/components';
import { ConfigService } from '@exb/config';
import { GraphQLModule } from '@exb/graphql';
import { LOG_LEVEL, LoggingService } from '@exb/logging';
import { PermissionsService } from '@exb/permissions';
import { ContextProviderService } from '@exb/router-utils';
import { SolutionDashboardModule } from '@exb/solution-dashboard';

import { environment } from '../environments/environment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MainLayoutModule } from './components/main-layout';
import { AuthenticateModule } from './pages/authenticate';
import { DEMComponent } from './pages/dem';
import { DocManagementModule } from './pages/document-management-layout';
import { DocumentViewLayoutModule } from './pages/document-view-layout';
import { NotFoundModule } from './pages/not-found';
import { NotificationModule } from './pages/notification';
import { ProfileModule } from './pages/profile';
import { SolutionBrowserModule } from './pages/solution-browser';
import { TranslocoRootModule } from './transloco-root.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,
    AuthenticateModule,
    BrowserAnimationsModule,
    BrowserModule,
    CommonModule,
    ConfirmDialogModule,
    ConfirmDialogComponent,
    DEMComponent,
    DocManagementModule,
    DocumentViewLayoutModule,
    GraphQLModule,
    MainLayoutModule,
    NotFoundModule,
    NotificationModule,
    ProfileModule,
    SocialLoginModule,
    SolutionBrowserModule,
    SolutionDashboardModule,
    ToastModule,
    TranslocoRootModule,
    StoreModule.forRoot({}),
    EffectsModule.forRoot([]),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      deps: [AuthenticationService],
      useFactory: (authentication: AuthenticationService) => () => authentication.init(),
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: ApiCallInterceptor, multi: true },
    {
      provide: 'SocialAuthServiceConfig',
      useFactory: (configService: ConfigService, loggingService: LoggingService) => {
        return {
          autoLogin: false,
          providers: [
            {
              id: GoogleLoginProvider.PROVIDER_ID,
              provider: new GoogleLoginProvider(configService.config.googleAuthClientId, { oneTapEnabled: false }),
            },
          ],
          onError: err => {
            loggingService.error('Error in social auth', err);
          },
        } as SocialAuthServiceConfig;
      },
      deps: [ConfigService, LoggingService],
    },
    ConfirmationService,
    ContextProviderService,
    {
      provide: APP_INITIALIZER,
      deps: [ContextProviderService, Router],
      useFactory: (contextProvider: ContextProviderService) => () => contextProvider.init(),
      multi: true,
    },
    {
      provide: APP_CONTEXT_PROVIDER,
      useExisting: ContextProviderService,
      deps: [Router],
    },
    {
      provide: LOG_LEVEL,
      deps: [ConfigService],
      useFactory: (configService: ConfigService) => configService.config.logLevel,
    },
    PermissionsService,
    provideHttpClient(withInterceptorsFromDi()),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
