import { Component, DestroyRef, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Router } from '@angular/router';
import {
  DOCUMENT_NOT_FOUND_ERROR_MESSAGE,
  DocSetInfo,
  DocumentCopies,
  DocumentService,
  DocumentSet,
  DocumentSetService,
  IDocument,
  Page,
} from '@exb/document';
import { DocumentSetManagementService } from '@exb/document-management';
import { DocumentViewStateService } from '@exb/document-view';
import { QSLevel, QSMetaCollection } from '@exb/quality';
import { SolutionService, SolutionStateService } from '@exb/solution';
import { isUndefined } from 'lodash-es';
import {
  BehaviorSubject,
  NEVER,
  Observable,
  Subject,
  catchError,
  combineLatest,
  filter,
  from,
  map,
  share,
  skip,
  startWith,
  switchMap,
} from 'rxjs';

@Component({
  selector: 'exb-document-view-layout',
  templateUrl: './document-view-layout.component.html',
  styleUrls: ['./document-view-layout.component.scss'],
  providers: [DocumentViewStateService, DocumentSetManagementService],
})
export class DocumentViewLayoutComponent {
  protected readonly currentDocumentId$: Observable<string>;

  protected readonly currentDocument$: Observable<IDocument>;
  protected readonly currentDocumentSet$: Observable<DocumentSet | undefined>;

  protected readonly currentDocumentIndex$: Observable<number>;
  protected readonly availableDocumentsCount$: Observable<number>;
  private readonly currentPage$: Subject<Page> = new Subject<Page>();

  protected qualityMetrics$: Observable<QSMetaCollection>;
  protected setsContainingDocument$: Observable<DocSetInfo[]>;
  protected documentCopies$: Observable<DocumentCopies>;

  private readonly $shouldSkipPageNotFoundError = new BehaviorSubject<boolean>(false);
  private readonly destroyRef = inject(DestroyRef);

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
    private readonly documentService: DocumentService,
    private readonly documentSetService: DocumentSetService,
    private readonly solutionService: SolutionService,
    private readonly solutionState: SolutionStateService,
    private readonly documentViewStateService: DocumentViewStateService,
  ) {
    this.documentViewStateService.setCurrentDocumentParams(this.activatedRoute.snapshot.params);
    this.documentViewStateService.setCurrentDocumentData(this.activatedRoute.snapshot.data);

    this.currentDocument$ = this.documentViewStateService.currentDocument$;
    this.currentDocumentId$ = this.documentViewStateService.currentDocumentId$;
    this.currentDocumentSet$ = this.documentViewStateService.currentDocumentSet$;

    this.currentDocumentIndex$ = this.documentViewStateService.currentDocumentIndex$;

    this.availableDocumentsCount$ = this.documentViewStateService.availableDocumentsCount$;

    this.documentViewStateService.skipDocumentNotFoundError$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(shouldSkip => {
        this.$shouldSkipPageNotFoundError.next(shouldSkip);
      });

    this.setsContainingDocument$ = this.currentDocumentId$.pipe(
      switchMap(documentId => this.documentSetService.getSetsByDocumentId(documentId)),
      share(),
    );

    combineLatest([this.setsContainingDocument$, this.currentDocumentSet$])
      .pipe(
        filter(([, currentSet]) => !isUndefined(currentSet)),
        filter(
          ([setsContainingDocument, currentSet]) =>
            !setsContainingDocument.map(docSetInfo => docSetInfo.id).includes(currentSet!.id),
        ),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => {
        this.goBack();
      });

    this.documentViewStateService
      .getThereAreNoDocuments()
      .pipe(skip(1), takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.goBack();
      });

    this.documentCopies$ = this.currentDocumentId$.pipe(
      switchMap(documentId =>
        this.documentService.getDocumentCopies(documentId).pipe(
          catchError(err => {
            if (err.message === DOCUMENT_NOT_FOUND_ERROR_MESSAGE && this.$shouldSkipPageNotFoundError.getValue()) {
              return NEVER;
            }
            throw err;
          }),
        ),
      ),
    );

    this.qualityMetrics$ = combineLatest([
      this.currentPage$.pipe(startWith(undefined)),
      this.currentDocument$,
      this.currentDocumentSet$.pipe(startWith(undefined)),
      this.solutionService.getSolutionQualityScore(this.solutionState.solution.id),
    ]).pipe(
      map(([page, document, documentSet, solutionQuality]) => {
        return {
          [QSLevel.OnPage]: { level: QSLevel.OnPage, score: page?.qualityScore },
          [QSLevel.OnSingleDocument]: { level: QSLevel.OnSingleDocument, score: document.qualityScore },
          [QSLevel.OnDocSet]: !documentSet ? undefined : { level: QSLevel.OnDocSet, score: documentSet.qualityScore },
          [QSLevel.OnAllDocuments]: documentSet
            ? undefined
            : { level: QSLevel.OnAllDocuments, score: solutionQuality.score },
        };
      }),
    );

    this.documentViewStateService
      .getNewDocumentId()
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        switchMap(newDocumentId =>
          from(
            this.router.navigate(['..', newDocumentId], {
              relativeTo: this.activatedRoute,
              replaceUrl: true,
              queryParams: this.activatedRoute.snapshot.queryParams,
            }),
          ),
        ),
      )
      .subscribe(() => {
        this.documentViewStateService.setCurrentDocumentParams(this.activatedRoute.snapshot.params);
      });
  }

  goBack() {
    this.router.navigate(['../..'], { relativeTo: this.activatedRoute });
  }

  goToPreviousDocument() {
    this.documentViewStateService.goToPreviousDocument();
  }

  goToNextDocument() {
    this.documentViewStateService.goToNextDocument();
  }

  setCurrentPage(page: Page) {
    this.currentPage$.next(page);
  }
}
