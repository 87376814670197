import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { ActivatedRouteSnapshot, NavigationEnd, Params, Router } from '@angular/router';
import { TranslocoService } from '@jsverse/transloco';
import { marker } from '@jsverse/transloco-keys-manager/marker';
import { MenuItem } from 'primeng/api/menuitem';
import { Observable, combineLatest, merge, of } from 'rxjs';
import { filter, map, mergeMap, switchMap } from 'rxjs/operators';

import { AuthenticationService } from '@exb/auth';
import { SidebarItem, SidebarItemCase, TranslationUtilsService } from '@exb/components';
import { ConfigService } from '@exb/config';
import { PermissionsService } from '@exb/permissions';

import { Title } from '@angular/platform-browser';

import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TitleData, TitleValue, UserNames } from '../../models';
import { UserService } from '../../services/user.service';

enum SidebarItems {
  Solutions,
  Modules,
  CustomerOverview,
  AdminBackend,
  Help,
}

@Component({
  selector: 'exb-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss'],
})
export class MainLayoutComponent implements OnInit {
  breadcrumbItems: MenuItem[] = [];

  readonly userNames$: Observable<UserNames>;
  menuItems$: Observable<SidebarItem[]>;

  private readonly destroyRef = inject(DestroyRef);

  constructor(
    private readonly router: Router,
    private readonly translocoService: TranslocoService,
    configService: ConfigService,
    userService: UserService,
    authenticationService: AuthenticationService,
    permissionsService: PermissionsService,
    translationUtils: TranslationUtilsService,
    private readonly titleService: Title,
  ) {
    this.userNames$ = authenticationService.userInfo$.pipe(
      filter(userInfo => typeof userInfo?.id !== 'undefined'),
      switchMap(userInfo => userService.getUserNames(userInfo!.id)),
    );

    const allMenuItems: SidebarItem[] = [
      {
        id: SidebarItems.Solutions,
        sidebarItemCase: SidebarItemCase.RouterLink,
        label: marker('sidebar.navigation.solutions.label'),
        icon: 'folder',
        link: 'customers',
        tooltip: marker('sidebar.navigation.solutions.tooltip'),
        testId: 'solution-browser-link',
      },
      {
        id: SidebarItems.Modules,
        sidebarItemCase: SidebarItemCase.RouterLink,
        label: marker('sidebar.navigation.modules.label'),
        icon: 'grid_view',
        link: 'modules',
        tooltip: marker('sidebar.navigation.modules.tooltip'),
        testId: 'module-editor-link',
      },
      {
        id: SidebarItems.CustomerOverview,
        sidebarItemCase: SidebarItemCase.RouterLink,
        label: marker('sidebar.navigation.customerOverview.label'),
        icon: 'person',
        class: 'bottom-slot',
        link: 'customer-overview',
        tooltip: marker('sidebar.navigation.customerOverview.tooltip'),
        testId: 'customer-overview-link',
      },
      {
        id: SidebarItems.AdminBackend,
        sidebarItemCase: SidebarItemCase.ExternalLink,
        label: marker('sidebar.navigation.adminBackend.label'),
        icon: 'launch',
        class: 'bottom-slot',
        href: '/redirect-to-admin-backend',
        tooltip: marker('sidebar.navigation.adminBackend.tooltip'),
        testId: 'admin-backend-link',
      },
      {
        id: SidebarItems.Help,
        sidebarItemCase: SidebarItemCase.ExternalLink,
        label: marker('sidebar.navigation.help.label'),
        icon: 'help',
        class: 'bottom-slot',
        href: configService.config.userManualUrl,
        tooltip: marker('sidebar.navigation.help.tooltip'),
        testId: 'help',
        showTooltipWhenExpanded: true,
      },
    ];

    const isStaff$ = authenticationService.userInfo$.pipe(map(userInfo => !!userInfo?.isStaff));

    const hasAccessToMultipleCustomers$ = permissionsService.hasAccessToMultipleCustomers();

    const canCreateCustomer$ = permissionsService.hasPermission({ resource: 'customer', action: 'create' });

    this.menuItems$ = combineLatest([isStaff$, hasAccessToMultipleCustomers$, canCreateCustomer$]).pipe(
      map(([isStaff, hasAccessToMultipleCustomers, canCreateCustomer]) => {
        return allMenuItems.filter(menuItem => {
          switch (menuItem.id) {
            case SidebarItems.Modules:
            case SidebarItems.AdminBackend:
              return isStaff;
            case SidebarItems.CustomerOverview:
              return isStaff || hasAccessToMultipleCustomers || canCreateCustomer;
            default:
              return true;
          }
        });
      }),
      switchMap(menuItems => translationUtils.translateFieldsInList<SidebarItem>(menuItems, 'label', 'tooltip')),
    );
  }

  ngOnInit(): void {
    const descendantRoutes$ = merge(
      this.translocoService.langChanges$,
      this.router.events.pipe(filter(event => event instanceof NavigationEnd)),
    ).pipe(
      map(() => {
        const root = this.router.routerState.snapshot.root;
        return this.getDescendantRoutes(root).filter(route => route.url.length > 0);
      }),
    );

    descendantRoutes$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        mergeMap(descendantRoutes => {
          const breadcrumbs$ = descendantRoutes
            .map(route => ({ route, titleValue: this.getTitleValue(route.data, 'breadcrumb') }))
            .filter(({ titleValue }) => Boolean(titleValue))
            .map(({ route, titleValue }) => this.getBreadcrumb$(route, titleValue!));
          return combineLatest(breadcrumbs$);
        }),
      )
      .subscribe(breadcrumbs => {
        if (breadcrumbs.length) {
          breadcrumbs[breadcrumbs.length - 1].routerLink = undefined;
        }
        this.breadcrumbItems = breadcrumbs;
      });
    descendantRoutes$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        mergeMap(descendantRoutes => {
          const titles$ = descendantRoutes
            .map(route => ({ route, titleValue: this.getTitleValue(route.data, 'page') }))
            .filter(({ titleValue }) => Boolean(titleValue))
            .map(({ route, titleValue }) => this.getTitle$(route, titleValue!));
          return combineLatest(titles$);
        }),
      )
      .subscribe(titles => {
        this.titleService.setTitle(titles.join(' \u2013 '));
      });
  }

  private getTitleValue(
    data: ActivatedRouteSnapshot['data'],
    type: keyof Exclude<TitleData, TitleValue>,
  ): TitleValue | undefined {
    const isTitleObject = typeof data['title'] === 'object' && data['title'] !== null;
    return isTitleObject ? data['title'][type] : data['title'];
  }

  private getBreadcrumb$(route: ActivatedRouteSnapshot, titleValue: TitleValue): Observable<MenuItem> {
    const routerLink = this.getRouteAbsoluteUrl(route);
    const data = route.data;
    const queryParamsObj: { queryParams?: Params } = data['preserveQueryParams']
      ? route.queryParams && { queryParams: route.queryParams }
      : {};

    return of(titleValue).pipe(
      mergeMap(breadcrumbData => {
        if (typeof breadcrumbData === 'function') {
          return of(breadcrumbData(data));
        }
        return this.translocoService.selectTranslate(breadcrumbData);
      }),
      map(label => ({ label, routerLink, ...queryParamsObj })),
    );
  }

  private getTitle$(route: ActivatedRouteSnapshot, titleValue: TitleValue): Observable<string> {
    const data = route.data;
    return of(titleValue).pipe(
      mergeMap(titleValue => {
        if (typeof titleValue === 'function') {
          return of(titleValue(data));
        }
        return this.translocoService.selectTranslate(titleValue);
      }),
    );
  }

  private getDescendantRoutes(root: ActivatedRouteSnapshot): ActivatedRouteSnapshot[] {
    const result: ActivatedRouteSnapshot[] = [];
    for (let route: ActivatedRouteSnapshot | null = root; route; route = route.firstChild) {
      result.push(route);
    }
    return result;
  }

  private getRouteAbsoluteUrl(leafRoute: ActivatedRouteSnapshot) {
    const urls: string[] = [];
    for (let route = leafRoute; route.parent; route = route.parent) {
      urls.unshift(...route.url.map(url => url.path));
    }
    return `/${urls.join('/')}`;
  }
}
