import { HttpErrorResponse } from '@angular/common/http';
import { Component, DestroyRef, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslocoModule, TranslocoService } from '@jsverse/transloco';
import { marker } from '@jsverse/transloco-keys-manager/marker';

import { ButtonModule } from 'primeng/button';

import { AppHeaderComponent, ToastsMessageService } from '@exb/components';
import { LoggingService } from '@exb/logging';

import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { SupportService } from '../../services/support.service';

@Component({
  selector: 'exb-support-request',
  standalone: true,
  imports: [AppHeaderComponent, ButtonModule, TranslocoModule],
  templateUrl: './support-request.component.html',
  styleUrl: './support-request.component.scss',
})
export class SupportRequestComponent {
  private readonly supportService = inject(SupportService);

  private readonly route = inject(ActivatedRoute);

  private readonly translocoService = inject(TranslocoService);

  private readonly toast = inject(ToastsMessageService);

  private readonly loggingService = inject(LoggingService);
  private readonly destroyRef = inject(DestroyRef);

  protected requestSupport() {
    const queryParams = this.route.snapshot.queryParams;
    const customerId: string | undefined = queryParams['customerId'] || undefined;
    const solutionId: string | undefined = queryParams['solutionId'] || undefined;

    this.supportService
      .requestSupport({ customerId, solutionId })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: () => {
          this.toast.showSuccess(this.translocoService.translate('supportRequest.requestSupportSuccess'));
        },
        error: error => {
          let errorMessageKey: string = marker('supportRequest.requestSupportError');
          if (error instanceof HttpErrorResponse) {
            errorMessageKey =
              error.status <= 0 ? marker('genericErrors.clientOffline') : marker('genericErrors.serverError');
          }
          const errorMessage = this.translocoService.translate(errorMessageKey);
          this.toast.showError(errorMessage);
          this.loggingService.error(errorMessage, error);
        },
      });
  }
}
