import { Injectable, inject } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable, map, of, switchMap, throwError } from 'rxjs';

import { ApolloProviders } from '@exb/graphql';

import {
  RequestNdaResponse,
  RequestNdaSuccess,
  RequestSupportMutationResponse,
  requestNdaMutation,
  requestSupportMutation,
} from './support.service.query';

@Injectable({
  providedIn: 'root',
})
export class SupportService {
  private readonly apollo = inject(Apollo);

  requestSupport({ customerId, solutionId }: { customerId?: string; solutionId?: string } = {}): Observable<boolean> {
    return this.apollo
      .use(ApolloProviders.nextApiMutation.clientName)
      .mutate<{ requestSupport: RequestSupportMutationResponse }>({
        mutation: requestSupportMutation,
        variables: { customerId, solutionId },
      })
      .pipe(
        map(({ data }) => {
          if (data?.requestSupport.__typename === 'RequestSupport') {
            return data.requestSupport.accepted;
          }
          throw new Error(data?.requestSupport.__typename || 'Empty response from the server!');
        }),
      );
  }

  requestNda(): Observable<boolean> {
    return this.apollo
      .use(ApolloProviders.nextApiMutation.clientName)
      .mutate<{ requestNda: RequestNdaResponse }>({
        mutation: requestNdaMutation,
      })
      .pipe(
        switchMap(({ data }) => {
          switch (data?.requestNda.__typename) {
            case 'RequestNDA': {
              const successResponse = data!.requestNda as RequestNdaSuccess;
              return of(successResponse.accepted);
            }
            default:
              return throwError(() => new Error('Error requesting NDA'));
          }
        }),
      );
  }
}
