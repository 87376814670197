import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService, Credentials } from '@exb/auth';
import { ToastsMessageService } from '@exb/components';
import { TranslocoService } from '@jsverse/transloco';
import { Subscription } from 'rxjs';

@Component({
  selector: 'exb-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
})
export class SignInComponent implements OnInit, OnDestroy {
  readonly signinForm = new FormGroup({
    email: new FormControl(''),
    password: new FormControl(''),
  });

  loading = false;

  language = 'en';

  private readonly subscriptions = new Subscription();

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly authenticationService: AuthenticationService,
    private readonly toast: ToastsMessageService,
    private readonly transloco: TranslocoService,
  ) {}

  ngOnInit(): void {
    this.language = this.transloco.getActiveLang();
    this.subscriptions.add(
      this.authenticationService.loginEvent$.subscribe(event => {
        this.loading = event.type === 'login-requested';
        if (event.type === 'login-successful') {
          const returnUrl = this.route.snapshot.queryParamMap.get('returnUrl') || '/';
          this.router.navigateByUrl(returnUrl, { replaceUrl: true });
        } else if (event.type === 'login-failed') {
          const error = event.error;
          if (error instanceof HttpErrorResponse && error.status === HttpStatusCode.Unauthorized) {
            const { password } = this.signinForm.controls;
            password.reset();
            password.markAsDirty();
            password.setErrors({ invalidCredential: true });
          } else if (error instanceof HttpErrorResponse && error.status <= 0) {
            this.toast.showError(this.transloco.translate('genericErrors.clientOffline'));
          } else {
            this.toast.showError(this.transloco.translate('genericErrors.serverError'));
          }
        }
      }),
    );
  }

  onSubmit() {
    const { email, password } = this.signinForm.controls;
    email.setValidators([Validators.required, Validators.email]);
    email.updateValueAndValidity();
    password.setValidators(Validators.required);
    password.updateValueAndValidity();

    if (!this.signinForm.valid) {
      email.markAsDirty();
      password.markAsDirty();
      return;
    }

    this.subscriptions.add(this.authenticationService.login(this.signinForm.value as Credentials).subscribe());
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
