import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '@exb/auth';
import { ToastsMessageService, defaultToastsKey } from '@exb/components';
import { SortingFilterStoreService } from '@exb/document-management';
import { Translation, TranslocoService } from '@jsverse/transloco';
import { MessageService, PrimeNGConfig } from 'primeng/api';
import { Observable, switchMap } from 'rxjs';

@Component({
  selector: 'exb-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [
    {
      provide: MessageService,
      useExisting: ToastsMessageService,
    },
  ],
})
export class AppComponent implements OnInit {
  readonly defaultToastsKey = defaultToastsKey;
  selectPrimeNgTranslationOnLangChange$?: Observable<Translation>;

  constructor(
    private readonly primeNGConfig: PrimeNGConfig,
    private readonly translocoService: TranslocoService,
    private readonly authenticationService: AuthenticationService,
    private readonly sortingFilterStoreService: SortingFilterStoreService,
  ) {}

  ngOnInit(): void {
    this.authenticationService.loggedInUserId$.subscribe(userId => {
      if (userId) {
        this.sortingFilterStoreService.getSFDataFromLocalStore(userId);
      }
    });
    // no unsubscribe needed since the component here is the app
    this.selectPrimeNgTranslationOnLangChange$ = this.translocoService.langChanges$.pipe(
      switchMap(() => this.translocoService.selectTranslateObject('primeng')),
    );
    this.selectPrimeNgTranslationOnLangChange$.subscribe(result => {
      this.primeNGConfig.setTranslation(result);
    });
  }
}
