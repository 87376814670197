<exb-authentication-layout *transloco="let t">
  <h1 class="heading-01 text-primary text-center">{{ t('signup.title') }}</h1>

  <fieldset [disabled]="loading" class="forms-container">
    <div
      class="flex flex-column gap-4"
      [style.visibility]="currentPage === 1 ? undefined : 'hidden'"
      #formContainer
      data-testid="credential-form-container">
      <!--
        The Google sign in button is rendered when formContainer has a non-null width because it blocks
        the rendering of the entire component when running Jest.
      -->
      <div class="google-sso-button-container">
        <asl-google-signin-button
          *ngIf="formContainer.offsetWidth"
          type="standard"
          logo_alignment="center"
          text="signup_with"
          [width]="240"
          [locale]="language"
          size="large">
        </asl-google-signin-button>
      </div>

      <div class="form-separator">
        <hr />
        <span class="body-01">{{ t('signin.separator') }}</span>
        <hr />
      </div>

      <form [formGroup]="credentialForm" (ngSubmit)="onCredentialSubmit()">
        <div class="field field-lg">
          <input
            type="email"
            pInputText
            formControlName="email"
            [placeholder]="t('signup.fields.email')"
            data-testid="email-input" />
          <ng-container *ngIf="credentialForm.controls.email.dirty">
            <small
              class="p-error block"
              *ngFor="let error of credentialForm.controls.email.errors | keyvalue"
              [attr.data-error-key]="error.key">
              {{
                error.key === 'required'
                  ? t('signup.errors.emptyEmail')
                  : error.key === 'email'
                    ? t('signup.errors.invalidEmail')
                    : error.value
              }}
            </small>
          </ng-container>
        </div>
        <div class="field field-lg">
          <span class="p-input-icon-right" [pTooltip]="t('signup.passwordRequirementInfoMessage')" [escape]="false">
            <input
              type="password"
              pPassword
              [feedback]="false"
              [showPassword]="showPassword"
              formControlName="password"
              [placeholder]="t('signup.fields.password')"
              data-testid="password-input" />
            <i
              class="cursor-pointer material-icons-round"
              [ngClass]="showPassword ? 'mi-visibility-off' : 'mi-visibility'"
              (click)="showPassword = !showPassword"
              (keyup.enter)="showPassword = !showPassword"
              tabindex></i>
          </span>
          <ng-container *ngIf="credentialForm.controls.password.dirty">
            <small
              class="p-error block"
              *ngFor="let error of credentialForm.controls.password.errors | keyvalue"
              [attr.data-error-key]="error.key">
              {{
                error.key === 'required'
                  ? t('signup.errors.emptyPassword')
                  : error.key === 'tooShort'
                    ? t('signup.errors.passwordTooShort')
                    : error.key === 'entirelyNumeric'
                      ? t('signup.errors.passwordEntirelyNumeric')
                      : error.key === 'requirementUnmet'
                        ? t('signup.errors.passwordRequirementUnmet')
                        : error.value
              }}
            </small>
          </ng-container>
        </div>
        <button pButton pRipple type="submit" class="p-button-block" data-testid="continue-button">
          {{ t('signup.actions.continue') }}
        </button>

        <div class="text-center mt-3">
          <span
            class="body-03 text-moderate white-space-break info-text-container"
            [innerHTML]="t('signup.legalContent')"></span>
        </div>

        <div class="text-center mt-3">
          <span class="body-03 text-moderate">{{ t('signup.signInMessage') }}</span>
          <p-button
            styleClass="p-button-link px-2 py-0"
            routerLink="/signin"
            queryParamsHandling="preserve"
            data-testid="signin-button"
            >{{ t('signup.links.signIn') }}
          </p-button>
        </div>
      </form>
    </div>

    <div
      class="flex flex-column gap-4"
      [style.visibility]="currentPage === 2 ? undefined : 'hidden'"
      data-testid="user-info-form-container">
      <span class="body-01 text-moderate text-center" [style.width.px]="formContainer.offsetWidth">
        {{ t('signup.userInfoMessage') }}
      </span>
      <form [formGroup]="userInfoForm" (ngSubmit)="onUserInfoSubmit()">
        <div class="field field-lg">
          <input
            type="text"
            pInputText
            formControlName="firstName"
            class="p-inputtext-custom-lg"
            [placeholder]="t('signup.fields.firstName')"
            data-testid="first-name-input" />
          <ng-container *ngIf="userInfoForm.controls.firstName.dirty">
            <small
              class="p-error block"
              *ngFor="let error of userInfoForm.controls.firstName.errors | keyvalue"
              [attr.data-error-key]="error.key">
              {{ error.key === 'required' ? t('signup.errors.emptyFirstName') : error.value }}
            </small>
          </ng-container>
        </div>
        <div class="field field-lg">
          <input
            type="text"
            pInputText
            formControlName="lastName"
            class="p-inputtext-custom-lg"
            [placeholder]="t('signup.fields.lastName')"
            data-testid="last-name-input" />
          <ng-container *ngIf="userInfoForm.controls.lastName.dirty">
            <small
              class="p-error block"
              *ngFor="let error of userInfoForm.controls.lastName.errors | keyvalue"
              [attr.data-error-key]="error.key">
              {{ error.key === 'required' ? t('signup.errors.emptyLastName') : error.value }}
            </small>
          </ng-container>
        </div>
        <button pButton pRipple type="submit" class="p-button-block" [loading]="loading" data-testid="signup-button">
          {{ loading ? '' : t('signup.actions.signUp') }}
        </button>
      </form>
    </div>
  </fieldset>
</exb-authentication-layout>
