import { APP_INITIALIZER, Injectable, NgModule, isDevMode } from '@angular/core';
import {
  TRANSLOCO_CONFIG,
  Translation,
  TranslocoConfig,
  TranslocoLoader,
  TranslocoModule,
  TranslocoService,
  getBrowserLang,
  provideTransloco,
  translocoConfig,
} from '@jsverse/transloco';
import {
  DateFormatOptions,
  Locale,
  TranslocoDateTransformer,
  TranslocoLocaleModule,
  provideTranslocoDateTransformer,
  provideTranslocoLocale,
} from '@jsverse/transloco-locale';
import { provideTranslocoMessageformat } from '@jsverse/transloco-messageformat';
import dayjs from 'dayjs';
import { firstValueFrom } from 'rxjs';

const availableLangs = ['en', 'de'];
const storedLanguage = localStorage.getItem('lang');
let browserLang = getBrowserLang();
if (browserLang && !availableLangs.includes(browserLang)) {
  browserLang = undefined;
}
const defaultLang = storedLanguage || browserLang || 'en';
const config: Partial<TranslocoConfig> = {
  availableLangs,
  defaultLang,
  fallbackLang: 'en',
  reRenderOnLangChange: true,
  prodMode: !isDevMode(),
  missingHandler: {
    useFallbackTranslation: true,
    logMissingKey: true,
    allowEmpty: false,
  },
};

const availableLocales = ['en-EN', 'de-DE'];
const localeConfig = {
  langToLocaleMapping: {
    en: 'en-EN',
    de: 'de-DE',
  },
};

@Injectable({ providedIn: 'root' })
export class TranslocoJsonLoader implements TranslocoLoader {
  getTranslation(lang: string) {
    return import(`../assets/i18n/${lang}.json`).then(res => res.default);
  }
}

export function loadTranslationsFactory(
  translocoService: TranslocoService,
  config: TranslocoConfig,
): () => Promise<Translation> {
  return () => {
    const lang = config.defaultLang;
    translocoService.setActiveLang(lang);
    const loadTranslations = translocoService.load(lang);
    return firstValueFrom(loadTranslations);
  };
}

export class CustomExbDateTransformer implements TranslocoDateTransformer {
  public transform(date: Date, locale: Locale, options: DateFormatOptions): string {
    if (locale === 'de-DE') {
      if (options.timeStyle === 'short') {
        return dayjs(date).format('DD.MM.YYYY, HH:mm');
      }
      return dayjs(date).format('DD.MM.YYYY');
    }
    if (options.timeStyle === 'short') {
      return dayjs(date).format('YYYY-MM-DD, h:mm A');
    }
    return dayjs(date).format('YYYY-MM-DD');
  }
}

@NgModule({
  exports: [TranslocoModule, TranslocoLocaleModule],
  providers: [
    provideTransloco({
      config: translocoConfig(config),
      loader: TranslocoJsonLoader,
    }),
    provideTranslocoLocale(localeConfig),
    provideTranslocoDateTransformer(CustomExbDateTransformer),
    provideTranslocoMessageformat({
      locales: availableLocales,
    }),
    {
      provide: APP_INITIALIZER,
      useFactory: loadTranslationsFactory,
      deps: [TranslocoService, TRANSLOCO_CONFIG],
      multi: true,
    },
  ],
})
export class TranslocoRootModule {}
