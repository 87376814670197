import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { loadDevMessages, loadErrorMessages } from '@apollo/client/dev';

import { APP_CONFIG, loadAppConfig } from '@exb/config';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
} else {
  loadDevMessages();
  loadErrorMessages();
}

loadAppConfig()
  .then(config => platformBrowserDynamic([{ provide: APP_CONFIG, useValue: config }]).bootstrapModule(AppModule))
  .catch(err => console.error(err));
