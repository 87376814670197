<ul class="list-none text-moderate label-01 m-0 p-0 mb-5" *transloco="let t">
  <li data-testid="documents-status">
    <ng-container *ngTemplateOutlet="resourceStatus; context: solutionStatus.documents"></ng-container>
    {{ t('solutionBrowser.solutionCard.body.documents') }}
  </li>
  <li data-testid="dem-status">
    <ng-container *ngTemplateOutlet="resourceStatus; context: solutionStatus.dem"></ng-container>
    {{ t('solutionBrowser.solutionCard.body.dem') }}
  </li>
  <li data-testid="workflow-status">
    <ng-container *ngTemplateOutlet="resourceStatus; context: solutionStatus.workflow"></ng-container>
    {{ t('solutionBrowser.solutionCard.body.workflow') }}
  </li>
  <li data-testid="gold-data-status">
    <ng-container *ngTemplateOutlet="resourceStatus; context: solutionStatus.goldData"></ng-container>
    {{ t('solutionBrowser.solutionCard.body.goldData') }}
  </li>
  <li data-testid="results-status">
    <ng-container *ngTemplateOutlet="resourceStatus; context: solutionStatus.results"></ng-container>
    {{ t('solutionBrowser.solutionCard.body.results') }}
  </li>
  <li data-testid="e2e-measurements-status">
    <ng-container *ngTemplateOutlet="resourceStatus; context: solutionStatus.e2eMeasurement"></ng-container>
    {{ t('solutionBrowser.solutionCard.body.e2eMeasurement') }}
  </li>
  <ng-template #resourceStatus let-isAccessible="isAccessible" let-isAvailable="isAvailable">
    <i
      class="mr-1"
      [ngClass]="
        !isAccessible
          ? 'material-icons-round mi-visibility-off'
          : isAvailable
            ? 'material-icons-round mi-check-circle'
            : 'material-icons-outlined mi-cancel'
      "
      [class.text-muted]="!isAccessible || !isAvailable"
      [pTooltip]="!isAccessible ? t('solutionDashboard.configuration.cantView.tooltip') : undefined"
      tooltipPosition="bottom"></i>
  </ng-template>
</ul>
<p
  exbEndTruncate
  [linesClamped]="descriptionClampedLines"
  class="text-moderate body-03 m-0"
  data-testid="solution-description">
  {{ solutionDescription }}
</p>
