import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';

@Component({
  selector: 'exb-authentication-layout',
  templateUrl: './authentication-layout.component.html',
  styleUrls: ['./authentication-layout.component.scss'],
})
export class AuthenticationLayoutComponent implements AfterViewInit {
  @ViewChild('contentContainer', { static: true })
  contentContainer!: ElementRef;

  contentInitialHeight = 0;

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.contentInitialHeight = (this.contentContainer.nativeElement as HTMLElement).offsetHeight;
    });
  }
}
