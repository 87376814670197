<div class="flex h-full" *transloco="let t">
  <exb-side-bar-menu
    [userNames]="(userNames$ | async)!"
    [items]="(menuItems$ | async)!"
    data-testid="exb-side-bar-menu"></exb-side-bar-menu>

  <div class="flex-grow-1 px-3 flex flex-column">
    <p-breadcrumb [model]="breadcrumbItems" data-testid="exb-breadcrumb"></p-breadcrumb>
    <div class="content-container overflow-auto flex-grow-1 shadow-medium border-round-top">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
