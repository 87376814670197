<div class="grid grid-nogutter h-full">
  <div class="col">
    <img class="exb-logo" src="assets/img/exb-logo-white.png" alt="exb-logo" />
  </div>
  <div
    class="col flex justify-content-center flex-wrap"
    [style.margin-top]="'calc(50vh - ' + contentInitialHeight / 2 + 'px)'">
    <div #contentContainer>
      <ng-content></ng-content>
    </div>
  </div>
</div>
